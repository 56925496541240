<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <!-- COPYRIGHT © {{ new Date().getFullYear() }} -->
      Made by
      <b-link class="ml-25" href="mailto:oren.qi@me.com" target="_blank">Oren Qi</b-link>
    </span>

    <span class="float-md-right d-none d-md-block">
      Hope you enjoy !
      <feather-icon icon="HeartIcon" size="21" class="text-danger stroke-current" />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
