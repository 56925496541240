<template>
  <layout-vertical>
    <router-view />

    <!-- Using navbar scoped slot -->
    <!-- <div slot="navbar" class="px-1">
      <p class="mb-0">I am in navbar, Everything else is removed</p>
    </div> -->

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <div slot="vertical-menu-header" class="d-flex align-items-center h-100">
      <h1 class="font-medium-5 mb-0 text-primary">Company Name</h1>
    </div> -->

    <breadcrumb slot="breadcrumb" />

    <div slot="footer">
      <app-footer />
    </div>

    <app-customizer v-if="showCustomizer" slot="customizer" />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import Breadcrumb from '../components/Breadcrumb.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    Navbar,
    Breadcrumb,
    AppFooter,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
